import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import './CSS/Dashboard.css';

export default function Dashboard() {
  const navigate = useNavigate();
  const currentUser = localStorage.getItem('user') || 'Admin';
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [statistics, setStatistics] = useState({
    totalUsers: 0,
    totalInvoices: 0,
    verifiedInvoices: 0,
    pendingInvoices: 0,
    recentInvoices: []
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      const formatted = now.toISOString()
        .replace('T', ' ')
        .slice(0, 19);
      setCurrentDateTime(formatted);
    };
    updateDateTime();
    const timer = setInterval(updateDateTime, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const usersResponse = await axios.get("https://artinvver.com/user.php");
        const users = usersResponse.data;

        const invoicesResponse = await axios.get("https://artinvver.com/product.php");
        const invoices = invoicesResponse.data;

        // Normalize and calculate verified invoices
        const verifiedInvoices = invoices.filter(invoice => 
          invoice.Verified === 'Yes' || invoice.Verified === '1'
        );

        // Sort and normalize recent invoices
        const recentInvoices = invoices
          .sort((a, b) => new Date(b['Sale Date']) - new Date(a['Sale Date']))
          .slice(0, 5)
          .map(invoice => ({
            ...invoice,
            Verified: invoice.Verified === 'Yes' || invoice.Verified === '1' ? 'Yes' : 'No'
          }));

        setStatistics({
          totalUsers: users.length,
          totalInvoices: invoices.length,
          verifiedInvoices: verifiedInvoices.length,
          pendingInvoices: invoices.length - verifiedInvoices.length,
          recentInvoices
        });
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        setIsLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  function logoutSubmit() {
    localStorage.setItem("login", "");
    localStorage.setItem("loginStatus", "Logged out successfully!");
    navigate("/");
  }

  if (isLoading) {
    return <div className="loading-state">Loading dashboard data...</div>;
  }

  return (
    <div className="dashboard-container">
      <div className="dashboard-header">
        <div className="header-info">
          <div className="timestamp">
            <span>Current Date and Time :</span>
            <span className="value">{currentDateTime}</span>
          </div>
          <div className="user-info">
            <h5>Wellcome, {currentUser}</h5>
          </div>
        </div>
        <div className="header-actions">
          <h1>Dashboard</h1>
          <button onClick={logoutSubmit} className="btn-logout">
            <span className="logout-icon">⎋</span>
            Logout
          </button>
        </div>
      </div>

      <div className="stats-grid">
        <div className="stat-card">
          <div className="stat-icon users">👥</div>
          <div className="stat-details">
            <h3>Total Users</h3>
            <p className="stat-value">{statistics.totalUsers}</p>
          </div>
        </div>

        <div className="stat-card">
          <div className="stat-icon invoices">📄</div>
          <div className="stat-details">
            <h3>Total Invoices</h3>
            <p className="stat-value">{statistics.totalInvoices}</p>
          </div>
        </div>

        <div className="stat-card">
          <div className="stat-icon verified">✓</div>
          <div className="stat-details">
            <h3>Verified Invoices</h3>
            <p className="stat-value">{statistics.verifiedInvoices}</p>
          </div>
        </div>

        <div className="stat-card">
          <div className="stat-icon pending">⏳</div>
          <div className="stat-details">
            <h3>Pending Verification</h3>
            <p className="stat-value">{statistics.pendingInvoices}</p>
          </div>
        </div>
      </div>

      <div className="dashboard-content">
        <div className="recent-activity">
          <h2>Recent Invoices</h2>
          <div className="table-container">
            <table className="activity-table">
              <thead>
                <tr>
                  <th>Invoice No</th>
                  <th>Date</th>
                  <th>Vehicle</th>
                  <th>Buyer</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {statistics.recentInvoices.map((invoice, index) => (
                  <tr key={index}>
                    <td>{invoice['Vehicle Stock']}</td>
                    <td>{invoice['Sale Date']}</td>
                    <td>{`${invoice.Year} ${invoice.Make} ${invoice.Model}`}</td>
                    <td>{invoice['Buyer Company']}</td>
                    <td>${invoice['Total Sales']}</td>
                    <td>
                      <span className={`status-badge ${invoice.Verified === 'Yes' ? 'verified' : 'pending'}`}>
                        {invoice.Verified}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="quick-actions">
          <h2>Quick Actions</h2>
          <div className="actions-grid">
            <Link to="/userlist" className="action-card">
              <span className="action-icon">👥</span>
              <span className="action-text">Manage Users</span>
            </Link>
            <Link to="/productlist" className="action-card">
              <span className="action-icon">📄</span>
              <span className="action-text">View Invoices</span>
            </Link>
            <Link to="/addproduct" className="action-card">
              <span className="action-icon">➕</span>
              <span className="action-text">Add Invoice</span>
            </Link>
            <Link to="/status" className="action-card">
              <span className="action-icon">📊</span>
              <span className="action-text">System Status</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}