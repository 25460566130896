import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import './CSS/Userlist.css';

function Userlist() {
  const [userData, setUserData] = useState([]);
  const [message, setMessage] = useState("");
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      const formatted = now.toISOString()
        .replace('T', ' ')
        .slice(0, 19);
      setCurrentDateTime(formatted);
    };
    updateDateTime();
    const timer = setInterval(updateDateTime, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get("https://artinvver.com/user.php");
        setUserData(response.data);
      } catch (error) {
        console.error(error);
        setMessage("Error loading users");
      } finally {
        setIsLoading(false);
      }
    };
    fetchUsers();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        const res = await axios.delete(`https://artinvver.com/user.php/?id=${id}`);
        setMessage(res.data.success);
        setUserData(userData.filter((user) => user.id !== id));
      } catch (error) {
        console.error("Error deleting user:", error);
        setMessage("Error deleting user");
      }
    }
  };

  const filteredUsers = userData.filter(user =>
    user.user.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.status.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="userlist-container">
      <div className="userlist-header">
        <div className="header-info">
          <div className="timestamp">
            <span>Current Date and Time :</span>
            <span className="value">{currentDateTime}</span>
          </div>
        </div>
        <h1>User Management</h1>
      </div>

      <div className="actions-bar">
        <Link to="/adduser" className="btn btn-primary">
          <span className="icon">+</span> Add New User
        </Link>
        <div className="search-box">
          <input
            type="text"
            placeholder="Search users..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
        </div>
      </div>

      {message && (
        <div className={`alert ${message.includes('success') ? 'alert-success' : 'alert-error'}`}>
          {message}
        </div>
      )}

      {isLoading ? (
        <div className="loading-state">Loading users...</div>
      ) : (
        <div className="table-container">
          <table className="users-table">
            <thead>
              <tr>
                <th>Sr.No</th>
                <th>Username</th>
                <th>Password</th>
                <th>Role</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{user.user}</td>
                  <td>
                    {user.pass}
                  </td>
                  <td>
                    <span className={`role-badge ${user.status}`}>
                      {user.status}
                    </span>
                  </td>
                  <td className="actions-cell">
                    <Link
                      to={`/edituser/${user.userid}`}
                      state={{ user }}
                      className="btn-edit"
                    >
                      Edit
                    </Link>
                    <button
                      className="btn-delete"
                      onClick={() => handleDelete(user.id)}
                    >
                      Delete
                    </button>
                    <Link to="/status" className="btn-status">
                      Status
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Userlist;