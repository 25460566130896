import React, { useState, useEffect } from 'react';
import logo from './assests/logo.jpeg';
import { useNavigate } from 'react-router-dom';
import './CSS/Login.css'; // We'll create this file next

export default function Login({ onLogout }) {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        user: '',
        pass: ''
    });
    const [error, setError] = useState('');
    const [msg, setMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('login')) {
            onLogout();
            navigate('/dashboard');
        }
        if (localStorage.getItem('loginStatus')) {
            setError(localStorage.loginStatus);
            localStorage.removeItem('loginStatus');
        }
    }, [navigate, onLogout]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        setError('');
    };

    const loginSubmit = async () => {
        if (!formData.user || !formData.pass) {
            setError('All fields are required!');
            return;
        }

        setIsLoading(true);
        try {
            const response = await fetch('https://artinvver.com/login.php', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user: formData.user,
                    pass: formData.pass
                })
            });

            const data = await response.json();
            
            if (data[0].result === 'Invalid username!' || data[0].result === 'Invalid password!') {
                setError(data[0].result);
            } else {
                setMsg(data[0].result);
                setTimeout(() => {
                    localStorage.setItem('login', true);
                    localStorage.setItem('user', formData.user);
                    localStorage.setItem('role', data[0].role);
                    navigate(data[0].role === 'admin' ? '/dashboard' : '/userdetail');
                }, 1000);
            }
        } catch (err) {
            setError('An error occurred. Please try again.');
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="login-container">
            <div className="login-card">
                <div className="login-content">
                    {error && <div className="alert alert-danger">{error}</div>}
                    {msg && <div className="alert alert-success">{msg}</div>}
                    
                    <div className="logo-container">
                        <img src={logo} alt="Company Logo" className="login-logo" />
                    </div>

                    <h1 className="login-title">Welcome Back</h1>
                    <p className="login-subtitle">Please enter your credentials to continue</p>

                    <form className="login-form" onSubmit={(e) => e.preventDefault()}>
                        <div className="form-group">
                            <label className="form-label">
                                <i className="fas fa-user"></i>
                                Username
                            </label>
                            <input
                                type="text"
                                name="user"
                                className="form-input"
                                value={formData.user}
                                onChange={handleInputChange}
                                placeholder="Enter your username"
                            />
                        </div>

                        <div className="form-group">
                            <label className="form-label">
                                <i className="fas fa-lock"></i>
                                Password
                            </label>
                            <input
                                type="password"
                                name="pass"
                                className="form-input"
                                value={formData.pass}
                                onChange={handleInputChange}
                                placeholder="Enter your password"
                            />
                        </div>

                        <button
                            className={`login-button ${isLoading ? 'loading' : ''}`}
                            onClick={loginSubmit}
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <span className="spinner"></span>
                            ) : (
                                'Sign In'
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
