import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Protected({ Component, allowedRoles, onLogout }) {
    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem('login')) {
            onLogout();
            navigate('/', { replace: true });
        } else if (allowedRoles && !allowedRoles.includes(localStorage.getItem('role'))) {
            navigate("/", { replace: true });
        }
    }, [navigate, allowedRoles, onLogout]);

    return <Component />;
}
