import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import './CSS/EditProduct.css';

function EditProduct() {
    const navigate = useNavigate();
    const { productId } = useParams();
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [product, setProduct] = useState({
        'Vehicle Stock': '',
        'Sale Date': '',
        'Year': '',
        'Make': '',
        'Model': '',
        'Vehicle Vin': '',
        'Vehicle Price': '',
        'Buyer Company': '',
        'Buyer Address': '',
        'Buyer City': '',
        'Total Sales': '',
        'Buyer RFC': '',
        'Verified': ''
    });
    const [message, setMessage] = useState('');

    useEffect(() => {
        const updateDateTime = () => {
            const now = new Date();
            const formatted = now.toISOString()
                .replace('T', ' ')
                .slice(0, 19);
            setCurrentDateTime(formatted);
        };
        updateDateTime();
        const timer = setInterval(updateDateTime, 1000);
        return () => clearInterval(timer);
    }, []);

    const fieldGroups = {
        'Vehicle Information': [
            'Vehicle Stock',
            'Vehicle Vin',
            'Year',
            'Make',
            'Model',
            'Vehicle Price'
        ],
        'Buyer Information': [
            'Buyer Company',
            'Buyer Address',
            'Buyer City',
            'Buyer RFC'
        ],
        'Sale Information': [
            'Sale Date',
            'Total Sales',
            'Verified'
        ]
    };

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const response = await axios.get(`https://artinvver.com/product.php/${productId}`);
                setProduct(response.data);
            } catch (error) {
                console.error("Error fetching Invoice details:", error);
                setMessage('Error loading invoice details.');
            } finally {
                setIsLoading(false);
            }
        };
        fetchProductDetails();
    }, [productId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        try {
            const response = await axios.put(`https://artinvver.com/product.php/${productId}`, product);
            if (response.data.success) {
                setMessage('Invoice updated successfully!');
                setTimeout(() => {
                    navigate('/productlist');
                }, 2000);
            }
        } catch (error) {
            console.error("Error updating Invoice:", error);
            setMessage('Failed to update Invoice.');
        } finally {
            setIsSaving(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProduct(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    if (isLoading) {
        return <div className="loading-state">Loading invoice details...</div>;
    }

    return (
        <div className="edit-product-container">
            <div className="edit-product-header">
                <div className="header-info">
                    <div className="timestamp">
                        <span>Current Date and Time (UTC - YYYY-MM-DD HH:MM:SS formatted):</span>
                        <span className="value">{currentDateTime}</span>
                    </div>
                </div>
                <h1>Edit Invoice #{productId}</h1>
            </div>

            {message && (
                <div className={`alert ${message.includes('success') ? 'alert-success' : 'alert-error'}`}>
                    {message}
                </div>
            )}

            <form onSubmit={handleSubmit} className="edit-form">
                {Object.entries(fieldGroups).map(([groupName, fields]) => (
                    <div key={groupName} className="form-section">
                        <h2>{groupName}</h2>
                        <div className="fields-grid">
                            {fields.map((field) => (
                                <div key={field} className="form-group">
                                    <label htmlFor={field}>{field}</label>
                                    {field === 'Verified' ? (
                                        <select
                                            id={field}
                                            name={field}
                                            value={product[field] || ''}
                                            onChange={handleChange}
                                            className="form-input"
                                            required
                                        >
                                            <option value="">Select verification status</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    ) : (
                                        <input
                                            id={field}
                                            type={
                                                field === 'Sale Date' ? 'date' :
                                                field === 'Year' || field.includes('Price') || field.includes('Sales') ? 'number' :
                                                'text'
                                            }
                                            name={field}
                                            value={product[field] || ''}
                                            onChange={handleChange}
                                            className="form-input"
                                            placeholder={`Enter ${field.toLowerCase()}`}
                                            required
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}

                <div className="form-actions">
                    <button 
                        type="button" 
                        className="btn btn-secondary"
                        onClick={() => navigate('/productlist')}
                    >
                        Cancel
                    </button>
                    <button 
                        type="submit" 
                        className="btn btn-primary"
                        disabled={isSaving}
                    >
                        {isSaving ? 'Updating...' : 'Update Invoice'}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default EditProduct;