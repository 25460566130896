import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import './CSS/Productlist.css';

function Productlist() {
    const [products, setProducts] = useState([]);
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

    useEffect(() => {
        const updateDateTime = () => {
            const now = new Date();
            const formatted = now.toISOString()
                .replace('T', ' ')
                .slice(0, 19);
            setCurrentDateTime(formatted);
        };
        updateDateTime();
        const timer = setInterval(updateDateTime, 1000);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch("https://artinvver.com/product.php");
                const data = await response.json();
                const normalizedData = data.map(product => ({
                    ...product,
                    'Verified': product['Verified'] === 'Yes' ? 'Yes' : 'No'
                }));
                setProducts(normalizedData);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        };
        fetchProducts();
    }, []);

    const deleteProduct = async (productId) => {
        if (window.confirm('Are you sure you want to delete this invoice?')) {
            try {
                const response = await fetch(`https://artinvver.com/product.php?id=${productId}`, {
                    method: "DELETE",
                });
                if (response.ok) {
                    setProducts(products.filter(product => `${product.Invoice}` !== `${productId}`));
                }
            } catch (error) {
                console.error("Error deleting Invoice:", error);
            }
        }
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const getSortIndicator = (key) => {
        if (sortConfig.key === key) {
            return <span className="sort-indicator">{sortConfig.direction === 'asc' ? ' ↑' : ' ↓'}</span>;
        }
        return null;
    };

    const filteredProducts = products
        .filter(product => 
            Object.entries(product).some(([key, value]) => {
                const searchValue = String(value).toLowerCase();
                const searchKey = key.toLowerCase();
                const searchTermLower = searchTerm.toLowerCase();
                
                if (key === 'Verified') {
                    if (searchTermLower.includes('yes') || searchTermLower.includes('verified')) {
                        return value === 'Yes';
                    }
                    if (searchTermLower.includes('no') || searchTermLower.includes('unverified')) {
                        return value === 'No';
                    }
                }
                
                return searchValue.includes(searchTermLower) || searchKey.includes(searchTermLower);
            })
        )
        .sort((a, b) => {
            if (!sortConfig.key) return 0;
            
            if (sortConfig.key === 'Verified') {
                const aValue = a[sortConfig.key] === 'Yes' ? 1 : 0;
                const bValue = b[sortConfig.key] === 'Yes' ? 1 : 0;
                return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
            }

            const aValue = a[sortConfig.key];
            const bValue = b[sortConfig.key];

            if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });

    return (
        <div className="productlist-container">
            <div className="productlist-header">
                <div className="header-info">
                    <div className="timestamp">
                        <span>Current Date and Time :</span>
                        <span className="value">{currentDateTime}</span>
                    </div>
                </div>
                <h1>Invoice Management</h1>
            </div>

            <div className="actions-bar">
                <Link to="/addproduct" className="btn btn-primary">
                    <span className="icon">+</span> Add New Invoice
                </Link>
                <div className="search-box">
                    <input
                        type="text"
                        placeholder="Search invoices..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-input"
                    />
                </div>
            </div>

            {isLoading ? (
                <div className="loading-state">Loading invoices...</div>
            ) : (
                <div className="table-container">
                    <table className="invoice-table">
                        <thead>
                            <tr>
                                <th onClick={() => handleSort('Vehicle Stock')} className="sortable-header">
                                    Vehicle Stock {getSortIndicator('Vehicle Stock')}
                                </th>
                                <th onClick={() => handleSort('Sale Date')} className="sortable-header">
                                    Sale Date {getSortIndicator('Sale Date')}
                                </th>
                                <th onClick={() => handleSort('Year')} className="sortable-header">
                                    Year {getSortIndicator('Year')}
                                </th>
                                <th onClick={() => handleSort('Make')} className="sortable-header">
                                    Make {getSortIndicator('Make')}
                                </th>
                                <th onClick={() => handleSort('Model')} className="sortable-header">
                                    Model {getSortIndicator('Model')}
                                </th>
                                <th onClick={() => handleSort('Vehicle Vin')} className="sortable-header">
                                    Vehicle Vin {getSortIndicator('Vehicle Vin')}
                                </th>
                                <th onClick={() => handleSort('Vehicle Price')} className="sortable-header">
                                    Vehicle Price {getSortIndicator('Vehicle Price')}
                                </th>
                                <th onClick={() => handleSort('Buyer Company')} className="sortable-header">
                                    Buyer Company {getSortIndicator('Buyer Company')}
                                </th>
                                <th onClick={() => handleSort('Buyer City')} className="sortable-header">
                                    Buyer City {getSortIndicator('Buyer City')}
                                </th>
                                <th onClick={() => handleSort('Total Sales')} className="sortable-header">
                                    Total Sales {getSortIndicator('Total Sales')}
                                </th>
                                <th onClick={() => handleSort('Verified')} className="sortable-header">
                                    Verified {getSortIndicator('Verified')}
                                </th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredProducts.map((product, index) => (
                                <tr key={index}>
                                    <td>{product['Vehicle Stock']}</td>
                                    <td>{product['Sale Date']}</td>
                                    <td>{product['Year']}</td>
                                    <td>{product['Make']}</td>
                                    <td>{product['Model']}</td>
                                    <td>{product['Vehicle Vin']}</td>
                                    <td>${product['Vehicle Price']}</td>
                                    <td>{product['Buyer Company']}</td>
                                    <td>{product['Buyer City']}</td>
                                    <td>${product['Total Sales']}</td>
                                    <td>
                                        <span className={`status-badge ${product['Verified'] === 'Yes' ? 'verified' : 'unverified'}`}>
                                            {product['Verified']}
                                        </span>
                                    </td>
                                    <td className="actions">
                                        <Link to={`/editproduct/${product.Invoice}`} className="btn-edit">
                                            Edit
                                        </Link>
                                        <button 
                                            onClick={() => deleteProduct(product.Invoice)} 
                                            className="btn-delete"
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

export default Productlist;