import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import './CSS/EditUser.css';

function EditUser() {
  const navigate = useNavigate();
  const { userid } = useParams();
  const location = useLocation();
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [user, setUser] = useState({
    user: "",
    pass: "",
    status: ""
  });
  const [message, setMessage] = useState("");

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      const formatted = now.toISOString()
        .replace('T', ' ')
        .slice(0, 19);
      setCurrentDateTime(formatted);
    };
    updateDateTime();
    const timer = setInterval(updateDateTime, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (location.state && location.state.user) {
      setUser(location.state.user);
      setIsLoading(false);
    } else {
      const fetchUserDetails = async () => {
        try {
          const response = await axios.get(`https://artinvver.com/user.php/${userid}`);
          setUser(response.data);
        } catch (error) {
          console.error("Error fetching user details:", error);
          setMessage("Error loading user details");
        } finally {
          setIsLoading(false);
        }
      };
      fetchUserDetails();
    }
  }, [userid, location]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    try {
      const response = await axios.put(`https://artinvver.com/user.php/${userid}`, user);
      if (response.data.success) {
        setMessage(response.data.success);
        setTimeout(() => {
          navigate("/userlist");
        }, 2000);
      }
    } catch (error) {
      console.error("Error updating user:", error);
      setMessage("Failed to update user");
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return <div className="loading-state">Loading user details...</div>;
  }

  return (
    <div className="edit-user-container">
      <div className="edit-user-header">
        <div className="header-info">
          <div className="timestamp">
            <span>Current Date and Time :</span>
            <span className="value">{currentDateTime}</span>
          </div>
        </div>
        <h1>Edit User Account</h1>
      </div>

      <div className="form-container">
        {message && (
          <div className={`alert ${message.includes('success') ? 'alert-success' : 'alert-success'}`}>
            {message}
          </div>
        )}

        <form onSubmit={handleSubmit} className="edit-form">
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              name="user"
              value={user.user}
              onChange={handleChange}
              className="form-input"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="text"
              id="password"
              name="pass"
              value={user.pass}
              onChange={handleChange}
              className="form-input"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="status">User Role</label>
            <select
              id="status"
              name="status"
              value={user.status}
              onChange={handleChange}
              className="form-input"
              required
            >
              <option value="">Select user role</option>
              <option value="admin">Admin</option>
              <option value="user">User</option>
            </select>
          </div>

          <div className="form-actions">
            <button 
              type="button" 
              className="btn btn-secondary"
              onClick={() => navigate('/userlist')}
            >
              Cancel
            </button>
            <button 
              type="submit" 
              className="btn btn-primary"
              disabled={isSaving}
            >
              {isSaving ? 'Updating...' : 'Update User'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditUser;