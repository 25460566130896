import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './CSS/Userdetail.css';

export default function Userdetail() {
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [vin, setVIN] = useState('');
    const [vehicleData, setVehicleData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const currentUser = localStorage.getItem('user') || 'Admin';

    useEffect(() => {
        const updateDateTime = () => {
            const now = new Date();
            const formatted = now.toISOString()
                .replace('T', ' ')
                .slice(0, 19);
            setCurrentDateTime(formatted);
        };
        updateDateTime();
        const timer = setInterval(updateDateTime, 1000);
        return () => clearInterval(timer);
    }, []);

    function logoutSubmit() {
        localStorage.setItem("login", "");
        localStorage.setItem("loginStatus", "Logged out successfully!");
        navigate("/");
    }

    const handleSearch = async () => {
        if (!vin.trim()) {
            setError('Please enter a VIN number');
            return;
        }
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(`https://artinvver.com/product.php/?vin=${vin}`);
            const data = await response.json();
            if (data.error) {
                setError('VIN NOT FOUND');
                setVehicleData(null);
            } else {
                // Normalize the verification status
                const normalizedData = {
                    ...data,
                    Verified: data.Verified === 'Yes' || data.Verified === '1' ? 'Yes' : 'No'
                };
                setVehicleData(normalizedData);
                setError(null);
            }
        } catch (error) {
            setError('Error searching VIN. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <div className="userdetail-container">
            <div className="userdetail-header">
                <div className="header-info">
                    <div className="timestamp">
                        <span>Current Date and Time :</span>
                        <span className="value">{currentDateTime}</span>
                    </div>
                    <div className="user-info">
                        <h5>Wellcome, {currentUser}</h5>
                    </div>
                </div>
                <div className="header-actions">
                    <h1>Invoice Validation System</h1>
                    <button onClick={logoutSubmit} className="btn-logout">
                        <span className="logout-icon">⎋</span>
                        Logout
                    </button>
                </div>
            </div>

            <div className="search-section">
                <div className="search-container">
                    <div className="search-box">
                        <input
                            type="text"
                            placeholder="Enter Vehicle VIN Number"
                            value={vin}
                            onChange={(e) => setVIN(e.target.value.toUpperCase())}
                            onKeyPress={handleKeyPress}
                            className="search-input"
                        />
                        <button
                            className="search-button"
                            onClick={handleSearch}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Searching...' : 'Search VIN'}
                        </button>
                    </div>
                    {error && (
                        <div className="error-message">
                            ⚠️ {error}
                        </div>
                    )}
                </div>

                {vehicleData && (
                    <div className="results-container">
                        <h2>Invoice Details</h2>
                        <div className="details-grid">
                            <div className="detail-card">
                                <h3>Vehicle Information</h3>
                                <div className="detail-items">
                                    <div className="detail-item">
                                        <span className="label">Invoice No</span>
                                        <span className="value">{vehicleData['Vehicle Stock']}</span>
                                    </div>
                                    <div className="detail-item">
                                        <span className="label">Year/Make/Model</span>
                                        <span className="value">{`${vehicleData.Year} ${vehicleData.Make} ${vehicleData.Model}`}</span>
                                    </div>
                                    <div className="detail-item">
                                        <span className="label">VIN</span>
                                        <span className="value highlight">{vehicleData['Vehicle Vin']}</span>
                                    </div>
                                    <div className="detail-item">
                                        <span className="label">Sale Date</span>
                                        <span className="value">{vehicleData['Sale Date']}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="detail-card">
                                <h3>Buyer Information</h3>
                                <div className="detail-items">
                                    <div className="detail-item">
                                        <span className="label">Company</span>
                                        <span className="value">{vehicleData['Buyer Company']}</span>
                                    </div>
                                    <div className="detail-item">
                                        <span className="label">Address</span>
                                        <span className="value">{vehicleData['Buyer Address']}</span>
                                    </div>
                                    <div className="detail-item">
                                        <span className="label">City</span>
                                        <span className="value">{vehicleData['Buyer City']}</span>
                                    </div>
                                    <div className="detail-item">
                                        <span className="label">RFC</span>
                                        <span className="value">{vehicleData['Buyer RFC']}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="detail-card">
                                <h3>Financial Information</h3>
                                <div className="detail-items">
                                    <div className="detail-item">
                                        <span className="label">Sales Price</span>
                                        <span className="value">${vehicleData['Vehicle Price']}</span>
                                    </div>
                                    <div className="detail-item">
                                        <span className="label">Total Sale</span>
                                        <span className="value">${vehicleData['Total Sales']}</span>
                                    </div>
                                    <div className="detail-item verification-status">
                                        <span className="label">Verification Status</span>
                                        <span className={`status-badge ${vehicleData.Verified === 'Yes' ? 'verified' : 'unverified'}`}>
                                            {vehicleData.Verified}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}