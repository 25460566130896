import React, { useState, useEffect } from 'react';
import './CSS/Status.css';

export default function Status() {
  const [currentDateTime, setCurrentDateTime] = useState('');

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      const formatted = now.toISOString()
        .replace('T', ' ')
        .slice(0, 19);
      setCurrentDateTime(formatted);
    };
    updateDateTime();
    const timer = setInterval(updateDateTime, 1000);
    return () => clearInterval(timer);
  }, []);

  const statusData = [
    {
      category: 'Session Information',
      items: [
        { label: 'Login', value: 'June 8, 2024 10:00 AM', icon: '🔑' },
        { label: 'Logout', value: 'June 8, 2024 6:00 PM', icon: '🚪' },
        { label: 'Last Seen', value: 'June 8, 2024 7:30 PM', icon: '👁️' },
        { label: 'Login Status', value: 'Logged in', icon: '✅' },
        { label: 'Online Status', value: 'Online', icon: '🟢' },
      ]
    },
    {
      category: 'User Information',
      items: [
        { label: 'User ID', value: '123456', icon: '🆔' },
        { label: 'Name of User', value: 'John Doe', icon: '👤' },
        { label: 'Username', value: 'johndoe123', icon: '📛' },
        { label: 'Current Role', value: 'User', icon: '👥' },
      ]
    },
    {
      category: 'VIN Information',
      items: [
        { label: 'Vin Number', value: 'ABC123', icon: '🔢' },
        { label: 'Vin Number Status', value: 'Verified Result', icon: '✔️' },
        { label: 'Vin Number Fail', value: '', icon: '❌' },
      ]
    },
    {
      category: 'Location Information',
      items: [
        { label: 'IP Address', value: '192.168.1.1', icon: '🌐' },
        { label: 'Country Name / Country Code', value: 'United States / US', icon: '🌍' },
      ]
    }
  ];

  return (
    <div className="status-container">
      <div className="status-header">
        <div className="header-info">
          <div className="timestamp">
            <span>Current Date and Time :</span>
            <span className="value">{currentDateTime}</span>
          </div>
        </div>
        <h1>User Activity Dashboard</h1>
      </div>

      <div className="status-grid">
        {statusData.map((section, index) => (
          <div key={index} className="status-card">
            <h2 className="card-title">{section.category}</h2>
            <div className="status-items">
              {section.items.map((item, itemIndex) => (
                <div key={itemIndex} className="status-item">
                  <div className="item-header">
                    <span className="item-icon">{item.icon}</span>
                    <span className="item-label">{item.label}</span>
                  </div>
                  <div className={`item-value ${!item.value ? 'empty' : ''}`}>
                    {item.value || 'Not Available'}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}