import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './CSS/AddUser.css';

function AddUser() {
  const navigate = useNavigate();
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [formValue, setFormValue] = useState({ 
    user: "", 
    pass: "", 
    status: "" 
  });
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      const formatted = now.toISOString()
        .replace('T', ' ')
        .slice(0, 19);
      setCurrentDateTime(formatted);
    };
    updateDateTime();
    const timer = setInterval(updateDateTime, 1000);
    return () => clearInterval(timer);
  }, []);

  const handleInput = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    try {
      const formData = { 
        user: formValue.user, 
        pass: formValue.pass, 
        status: formValue.status 
      };
      const res = await axios.post("https://artinvver.com/user.php", formData);

      if (res.data.success) {
        setMessage(res.data.success);
        setTimeout(() => {
          navigate("/userlist");
        }, 2000);
      }
    } catch (error) {
      setMessage("Error adding user. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="add-user-container">
      <div className="add-user-header">
        <div className="header-info">
          <div className="timestamp">
            <span>Current Date and Time : </span>
            <span className="value">{currentDateTime}</span>
          </div>
        </div>
        <h1>Add New User</h1>
      </div>

      <div className="form-container">
        {message && (
          <div className="alert alert-success">
            {message}
          </div>
        )}

        <form onSubmit={handleSubmit} className="user-form">
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              name="user"
              value={formValue.user}
              onChange={handleInput}
              className="form-input"
              placeholder="Enter username"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="text"
              id="password"
              name="pass"
              value={formValue.pass}
              onChange={handleInput}
              className="form-input"
              placeholder="Enter password"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="status">User Role</label>
            <select
              id="status"
              name="status"
              value={formValue.status}
              onChange={handleInput}
              className="form-input"
              required
            >
              <option value="">Select user role</option>
              <option value="admin">Admin</option>
              <option value="user">User</option>
            </select>
          </div>

          <div className="form-actions">
            <button 
              type="button" 
              className="btn btn-secondary"
              onClick={() => navigate('/userlist')}
            >
              Cancel
            </button>
            <button 
              type="submit" 
              className="btn btn-primary"
              disabled={isLoading}
            >
              {isLoading ? 'Adding User...' : 'Add User'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddUser;